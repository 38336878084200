<template>
  <el-form label-width="120px" label-position="top" size="mini">
    <el-collapse-item title="Источник данных">
      <source-data v-model="value.sourceData"></source-data>
      <div v-if="value.sourceData.entityId">
        <el-checkbox v-model="value.sourceData.customSorting">Произвольная сортировка</el-checkbox>
        <el-form-item label="Поле для сортировки внутри столбца">
          <select-field v-model="value.sourceData" property="sorting"></select-field>
        </el-form-item>
        <el-form-item label="Сортировать" v-if="value.sourceData.sorting">
          <el-radio-group v-model="value.sourceData.sortingDirection">
            <el-radio-button label="ASC">По возрастанию</el-radio-button>
            <el-radio-button label="DESC">По убыванию</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </div>
      <el-form-item label="Лимит записей">
        <el-input type="number" size="small" v-model="value.sourceData.limit">
        </el-input>
      </el-form-item>
      <editor-filters v-model="value.sourceData.filters"></editor-filters>
    </el-collapse-item>
    <el-collapse-item title="Группировка столбцов" v-if="value.sourceData.entityId">
      <el-checkbox v-model="value.listData.isAdditionalSource">Дополнительный источник <br/>данных</el-checkbox>
      <div v-if="value.listData.isAdditionalSource">
        <source-data v-model="value.listData"></source-data>
      </div>
      <div v-if="value.listData.isAdditionalSource === false">
        <el-form-item :label="$locale.interface_editor.component_editor.editor_tasks_source.group.field">
          <select-field v-model="value.sourceData" property="field"></select-field>
        </el-form-item>
      </div>
      <div v-if="value.listData.isAdditionalSource && value.listData.entityId">
        <el-checkbox v-model="value.sourceData.fieldIsLink">
          Поле из основного источника<br> является ссылкой<br> на источник группировки
        </el-checkbox>
        <el-form-item v-if="value.sourceData.fieldIsLink" label="Поле из основного источника">
          <select-field v-model="value.sourceData" property="field"></select-field>
        </el-form-item>
        <el-form-item v-if="value.sourceData.fieldIsLink === false" label="Сопоставление полей">
          <el-button type="text" @click="dialogSettingsVisible = true">{{ $locale.main.button.edit }}</el-button>
          <el-dialog title="Сопоставление столбцов"
                     :modal="false"
                     :visible.sync="dialogSettingsVisible">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item label="Поле из основного источника">
                  <select-field v-model="value.sourceData" property="field"></select-field>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="value.sourceData.fieldIsLink === false">
                <el-form-item label="Поле из источника группировки">
                  <select-field v-model="value.listData" property="field"></select-field>
                </el-form-item>
              </el-col>
            </el-row>
          </el-dialog>
        </el-form-item>
      </div>
      <el-form-item :label="$locale.interface_editor.component_editor.editor_tasks_source.additional_fields">
        <additional-fields v-model="value.listData"></additional-fields>
      </el-form-item>
      <el-form-item label="Поле для сортировки">
        <el-checkbox v-if="value.listData.isAdditionalSource" v-model="value.listData.sortingByAdditionalField">
          Из дополнительных полей
        </el-checkbox>
        <el-input v-if="value.listData.sortingByAdditionalField || !value.listData.isAdditionalSource" placeholder="Поле строкой" v-model="value.listData.sorting"></el-input>
        <select-field v-else-if="this.value.listData.isAdditionalSource" v-model="value.listData" property="sorting"></select-field>
      </el-form-item>
      <el-form-item label="Сортировать" v-if="value.listData.sorting || !value.listData.isAdditionalSource">
        <el-radio-group v-model="value.listData.sortingDirection">
          <el-radio-button label="ASC">По возрастанию</el-radio-button>
          <el-radio-button label="DESC"> По убыванию</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <editor-filters v-model="value.listData.filters"></editor-filters>
    </el-collapse-item>
  </el-form>
</template>

<script>
  import AdditionalFields from "./AdditionalFields";
  import SourceData from '@/components/InterfaceEditor/components/editor/Tasks/SourceData.vue'
  import EditorSelect from '@/components/InterfaceEditor/components/editor/editor-select'
  import EditorRegistrySelect from '@/components/InterfaceEditor/components/editor/editor-registry-select'
  import RegistrySelectTree from '@/components/Common/RegistrySelectTree.vue'
  import TreeSelect from '@/components/Common/TreeSelect.vue'
  import SelectField from "./SelectField"
  import EditorNumber from '@/components/InterfaceEditor/components/editor/editor-number'
  import FilterBuilder, { EComponentTypes } from '../../utils'
  import EditorFilters from "../editor-filters";

  export default {
    name: 'editor-tasks-source',
    components: {
      EditorFilters,
      FilterBuilder,
      EditorSelect,
      EditorRegistrySelect,
      RegistrySelectTree,
      TreeSelect,
      SourceData,
      SelectField,
      AdditionalFields,
      EditorNumber
    },
    props: {
      value: {
        type: Object
      }
    },
    data () {
      return {
        dialogSettingsVisible: false
      }
    },
    watch: {
      'value.listData.isAdditionalSource': {
        handler () {
          this.value.listData.sorting = null
        }
      },
      'value.listData.fieldIsLink': {
        handler () {
          this.value.listData.field = null
        }
      },
      'value.listData.sortingByAdditionalField': {
        handler () {
          this.value.listData.sorting = null
        }
      },
      value: {
        handler (value) {
          this.$emit('change', value)
        },
        deep: true
      },
    },
    mounted() {
      this.value = this.updateParams(this.value)
    },
    methods: {
      updateParams (source) {
        if (source.sourceData === undefined && source.listData === undefined) {
          let newSource = {}
          newSource.listData = {}
          newSource.listData.additionalFields = source.additionalFields
          newSource.listData.isAdditionalSource = false
          newSource.listData.filters = []
          newSource.sourceData = {}
          newSource.sourceData.entityId = source.entityId
          newSource.sourceData.type = source.type
          newSource.sourceData.field = parseInt(source.group_list_field.match(/\d+/))
          newSource.sourceData.sortingDirection = 'ASC'
          newSource.sourceData.filters = this.$attrs.otherProperties.filters
          newSource.sourceData.limit = this.$attrs.limit
          return newSource
        }
        return source
      },
      open() {
        this.$alert('This is a message', 'Title', {
          confirmButtonText: 'OK',
          callback: action => {
            this.$message({
              type: 'info',
              message: `action: ${ action }`
            });
          }
        });
      },
      async getFields(objectId){
        if(!objectId){
          return []
        }else{
          let res = await this.$http
            .get(`${this.$config.api}/objecteditor/entities?parent_id=${objectId}&show_children=true`)
          return res.data.data
        }
      }
    }
  }
</script>

<style scoped>

</style>
